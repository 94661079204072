window.addEventListener('load', function () {
    const mainHeader = document.querySelector('.main-header');
    const hamburguerItem = document.querySelector('.main-header__hamburguer');
    const headerMenus = document.querySelector('.main-header__menus--mobile');
    hamburguerItem.addEventListener('click', (e) => {
        hamburguerItem.classList.toggle('active');
        headerMenus.classList.toggle('active');
        mainHeader.classList.toggle('active');
        document.querySelector('body').classList.toggle('no-scroll');
    })
})

window.addEventListener('load', () => {
    const teamItems = document.querySelectorAll('.team__item');
    const shadow = document.querySelector('.shadow');
    const body = document.querySelector('body');

    const activateModal = (item) => {
        item.classList.add('active');
        shadow.classList.add('active');
        body.classList.add('no-scroll');
    };

    const deactivateModal = () => {
        const activeItem = document.querySelector('.team__item.active');
        if (activeItem) activeItem.classList.remove('active');
        shadow.classList.remove('active');
        body.classList.remove('no-scroll');
    };

    teamItems.forEach(item => {
        item.addEventListener('click', (event) => {
            if (event.target.classList[0] !== "team__item--link-in") activateModal(item);
        });
    });

    shadow.addEventListener('click', deactivateModal);

    document.querySelectorAll('.team__item--modal-close').forEach(button => {
        button.addEventListener('click', (event) => {
            event.stopPropagation();
            deactivateModal();
        });
    });
});

window.addEventListener('load', () => {
    const fundReportsSelect = document.querySelector('.fund-reports .fund-reports__select-item');
    const fundReportsData = document.querySelectorAll('.fund-reports .fund-reports__content');

    if (!fundReportsSelect || fundReportsData.length === 0) {
        console.warn('Elementos necessários não encontrados.');
        return;
    }

    const updateActiveReport = (selectedYear) => {
        fundReportsData.forEach((report) => {
            const reportYear = report.getAttribute('data-report-year');
            report.classList.toggle('active', reportYear === selectedYear);
        });
    };

    fundReportsSelect.addEventListener('change', (event) => {
        updateActiveReport(event.target.value);
    });

    const initialYear = fundReportsSelect.value || fundReportsData[0]?.getAttribute('data-report-year');
    if (initialYear) {
        updateActiveReport(initialYear);
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const fundTabs = document.querySelectorAll('.our-funds__tab');
    const contentTabs = document.querySelectorAll('.our-funds__content');

    if (fundTabs.length === 0 || contentTabs.length === 0) {
        console.warn('Nenhuma aba ou conteúdo encontrado.');
        return;
    }

    fundTabs[0].classList.add('active');
    contentTabs[0].classList.add('active');

    fundTabs.forEach(tab => {
        tab.addEventListener('click', () => {
            document.querySelector('.our-funds__tab.active')?.classList.remove('active');
            document.querySelector('.our-funds__content.active')?.classList.remove('active');

            tab.classList.add('active');
            const targetContent = document.querySelector(`.our-funds__content[data-content="${tab.getAttribute('data-content')}"]`);
            targetContent?.classList.add('active');

            if (!targetContent.classList.contains('our-funds__content--single')) {
                document.querySelector('.our-funds__subcontent-selector-item.active').classList.remove('active')
                document.querySelector('.our-funds__subcontent-item.active').classList.remove('active')
                targetContent.querySelector('.our-funds__subcontent-selector-item').classList.add('active')
                targetContent.querySelector('.our-funds__subcontent-item').classList.add('active')
            }
        });
    });

    // Subtabs
    const fundSubTabs = document.querySelectorAll('.our-funds__subcontent-selector-item');
    const contentSubTabs = document.querySelectorAll('.our-funds__subcontent-item');

    if (fundSubTabs.length === 0 || contentSubTabs.length === 0) {
        console.warn('Nenhuma aba ou conteúdo encontrado.');
        return;
    }

    fundSubTabs[0].classList.add('active');
    contentSubTabs[0].classList.add('active');

    fundSubTabs.forEach(tab => {
        tab.addEventListener('click', () => {
            document.querySelector('.our-funds__subcontent-selector-item.active')?.classList.remove('active');
            document.querySelector('.our-funds__subcontent-item.active')?.classList.remove('active');

            tab.classList.add('active');
            const targetContent = document.querySelector(`.our-funds__subcontent-item[data-content="${tab.getAttribute('data-content')}"]`);
            targetContent?.classList.add('active');
        });
    });
});

document.addEventListener('DOMContentLoaded', () => {
    const faq = document.querySelectorAll('.faq');
    if (!faq) return;

    faq[0].addEventListener('click', (e) => {
        if (!e.target.closest('.faq__item--answer')) {
            const question = e.target.closest('.faq__item');
            question.classList.toggle('active');
        }
    })
});

// Modal nossos fundos
document.addEventListener('DOMContentLoaded', () => {
    const btnOpenModal = document.querySelectorAll('.our-funds__content-link--open-modal');
    const btnsFundsModalClose = document.querySelectorAll('.our-funds__modal-close');
    const shadow = document.querySelector('.shadow');

    btnOpenModal.forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            document.querySelector(`.our-funds__modal[data-content="${e.target.getAttribute('data-content')}"]`).classList.add('active')

            document.querySelector(`.our-funds__modal .our-funds__fund-performance-menu-item.active`)?.classList.remove('active')
            document.querySelector(`.our-funds__modal[data-content="${e.target.getAttribute('data-content')}"] .our-funds__fund-performance-menu-item`)?.classList.add('active')

            document.querySelector(`.our-funds__modal .our-funds__fund-performance-content-item.active`)?.classList.remove('active')
            document.querySelector(`.our-funds__modal[data-content="${e.target.getAttribute('data-content')}"] .our-funds__fund-performance-content-item`)?.classList.add('active')

            shadow.classList.add('active')
        })
    })

    btnsFundsModalClose.forEach(button => {
        button.addEventListener('click', (event) => {
            document.querySelector('.our-funds__modal.active').classList.remove('active');
            shadow.classList.remove('active');
        });
    });

    shadow.addEventListener('click', () => {
        document.querySelector('.our-funds__modal.active')?.classList.remove('active');
        shadow.classList.remove('active');
    })

    const fundPerformanceContentItem = document.querySelectorAll('.our-funds__fund-performance-menu-item')

    fundPerformanceContentItem.forEach(el => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            document.querySelector('.our-funds__fund-performance-menu-item.active')?.classList.remove('active')
            document.querySelector('.our-funds__fund-performance-content-item.active')?.classList.remove('active')
            el.classList.add('active');
            document.querySelector(`.our-funds__fund-performance-content-item[data-content="${el.getAttribute('data-content')}"]`)?.classList.add('active');
        })
    })
});

window.addEventListener('load', () => {
    const ourFundReportsSelect = document.querySelectorAll('.our-funds__fund-reports--select-item');
    const ourFundReportsData = document.querySelectorAll('.our-funds__fund-reports-item');

    if (!ourFundReportsSelect || ourFundReportsData.length === 0) {
        console.warn('Elementos necessários não encontrados.');
        return;
    }

    const updateActiveReport = (selectedYear) => {
        ourFundReportsData.forEach((report) => {
            const reportYear = report.getAttribute('data-report-year');
            report.classList.toggle('active', reportYear === selectedYear);

            if (selectedYear === "---") {
                ourFundReportsData.forEach(el => el.classList.add('active'));
            }
        });
    };

    ourFundReportsSelect.forEach(el => {
        el.addEventListener('change', (event) => {
            updateActiveReport(event.target.value);
        });
    })

    const initialYear = ourFundReportsSelect.value || ourFundReportsData[0]?.getAttribute('data-report-year');
    if (initialYear) {
        updateActiveReport(initialYear);
    }
});

// Modal nossos fundos
document.addEventListener('DOMContentLoaded', () => {
    const btnOpenModalReport = document.querySelectorAll('.our-funds__content-link--report');
    const btnsFundsModalCloseReport = document.querySelectorAll('.our-funds__fund-reports .our-funds__modal-close');
    const shadow = document.querySelector('.shadow');

    btnOpenModalReport.forEach((el) => {
        el.addEventListener('click', (e) => {
            e.preventDefault();

            document.querySelector(`.our-funds__modal[data-content="${e.target.getAttribute('data-content')}"]`).classList.add('active')
            shadow.classList.add('active')
        })
    })

    btnsFundsModalCloseReport.forEach(button => {
        button.addEventListener('click', (event) => {
            document.querySelector('.our-funds__modal.active')?.classList.remove('active');
            shadow.classList.remove('active');
        });
    });

    shadow.addEventListener('click', () => {
        document.querySelector('.our-funds__modal.active')?.classList.remove('active');
        shadow.classList.remove('active');
    })
});

// contact form
document.addEventListener('DOMContentLoaded', () => {
    const closeRespose = document.querySelector('.contact-form__success-close');
    const wpcf7Elm = document.querySelector('.contact-form .wpcf7-form');
    const cfSuccess = document.querySelector('.contact-form__success');
    const shadow = document.querySelector('.shadow');

    wpcf7Elm?.addEventListener('wpcf7mailsent', function (event) {
        cfSuccess.classList.add('active');
        shadow.classList.add('active')
    }, false);

    closeRespose?.addEventListener('click', () => {
        shadow.classList.remove('active');
        cfSuccess.classList.remove('active');
    })

    shadow.addEventListener('click', () => {
        shadow.classList.remove('active');
        cfSuccess?.classList.remove('active');
    })
});

// Nossos RIS

window.addEventListener('load', () => {
    const risItems = document.querySelectorAll('.who-are-they__item');
    const shadow = document.querySelector('.shadow');
    const body = document.querySelector('body');

    const activateModal = (item) => {
        item.classList.add('active');
        shadow.classList.add('active');
        body.classList.add('no-scroll');
    };

    const deactivateModal = () => {
        const activeItem = document.querySelector('.who-are-they__item.active');
        if (activeItem) activeItem.classList.remove('active');
        shadow.classList.remove('active');
        body.classList.remove('no-scroll');
    };

    risItems.forEach(item => {
        item.addEventListener('click', (event) => {
            event.preventDefault();
            if (event.target.classList[0] !== "who-are-they__item--link-in") activateModal(item);
        });
    });

    shadow.addEventListener('click', deactivateModal);

    document.querySelectorAll('.who-are-they__item--modal-close').forEach(button => {
        button.addEventListener('click', (event) => {
            event.stopPropagation();
            deactivateModal();
        });
    });
});

// featured articles
document.addEventListener("DOMContentLoaded", (event) => {
    const swiper = new Swiper(".featured-articles__list", {
        slidesPerView: "auto",
        spaceBetween: 30,

    });
});

document.addEventListener("DOMContentLoaded", (event) => {
    const insightsItems = document.querySelectorAll('.insights__block-item');
    const closeInsightsItems = document.querySelectorAll('.insights__modal-close');
    const shadow = document.querySelector('.shadow');

    insightsItems.forEach(element => {
        element.addEventListener('click', () => {
            let itemName = element.getAttribute('data-open')
            document.querySelector('.insights__modal[data-open="' + itemName + '"]').classList.add('active')
            shadow.classList.add('active')
        })
    });

    closeInsightsItems.forEach(element => {
        element.addEventListener('click', (event) => {
            event.stopPropagation();
            document.querySelector('.insights__modal.active').classList.remove('active')
            shadow.classList.remove('active')
        })
    });

    shadow.addEventListener('click', () => {
        document.querySelector('.insights__modal.active').classList.remove('active')
        shadow.classList.remove('active')
    })
});

document.addEventListener("DOMContentLoaded", (event) => {
    Fancybox.bind("[data-fancybox]", {
        // Your custom options
    });
});

document.addEventListener("DOMContentLoaded", (event) => {
    const shareCopyItem = document.querySelector('.content-article__item--copy a');

    shareCopyItem?.addEventListener('click', async (e) => {
        e.preventDefault();
        let content = e.target.getAttribute('href');

        try {
            await navigator.clipboard.writeText(content);
            console.log("Texto copiado: " + content);
        } catch (err) {
            console.log("Falha ao copiar: " + err);
        }
    })
});

document.addEventListener("DOMContentLoaded", (event) => {
    const swiper2 = new Swiper(".insights__block-list", {
        slidesPerView: "auto",
        spaceBetween: 30,
    });
});

document.addEventListener("DOMContentLoaded", (event) => {
    const swiper = new Swiper(".pillars__list", {
        rewind: true,
        spaceBetween: 16,
        centeredSlides: true,
        slidesPerView: "auto",
        pagination: {
            el: ".swiper-pagination",
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });

    if (window.innerWidth >= 1024) {
        swiper.destroy()
    }
});

document.addEventListener("DOMContentLoaded", (event) => {
    const swiper = new Swiper(".social-impact__list", {
        rewind: true,
        spaceBetween: 16,
        centeredSlides: true,
        slidesPerView: "auto",
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });

    if (window.innerWidth >= 1024) {
        swiper.destroy()
    }
});